@import '@/styles/breakpoints.module';

.pageOverlayElementsStack {
  pointer-events: none;

  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: var(--zIndices-banner);
  //& > *:not(:last-child) {
  //  margin-bottom: 20px;
  //}
  & > * {
    pointer-events: auto;
  }
}
