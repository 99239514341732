@import 'breakpoints.module';

:root {
  --palette-black: #000000;
  --palette-white: #ffffff;
  --palette-primary-900: #0034ff;
  --palette-primary-800: #2955ff;
  --palette-primary-700: #3760ff;
  --palette-primary-700-50: rgba(55, 96, 255, 0.5);
  --palette-primary-600: #4a6fff;
  --palette-primary-500: #5a7cff;
  --palette-primary-400: #7692ff;
  --palette-primary-300: #8ba3ff;
  --palette-primary-200: #bdcbff;
  --palette-primary-100: #dde4ff;
  --palette-primary-75: #eef2ff;
  --palette-primary-50: #fafbff;
  --palette-gray-900: #0a0a0a;
  --palette-gray-800: #141414;
  --palette-gray-700: #212121;
  --palette-gray-600: #383838;
  --palette-gray-500: #555556;
  --palette-gray-400: #717172;
  --palette-gray-300: #969697;
  --palette-gray-200: #b5b5b5;
  --palette-gray-150: #e2e2e2;
  --palette-gray-125: #ededed;
  --palette-gray-100: #f0f0f0;
  --palette-gray-175: #ededed;
  --palette-gray-70: #f8f8f8;
  --palette-gray-50: #fcfcfc;
  --palette-green-50: #f9fffb;
  --palette-green-100: #e9ffea;
  --palette-green-400: #52cf88;
  --palette-green-600: #24a95d;
  --palette-green-700: #2a8552;
  --palette-red-50: #fff6f5;
  --palette-red-400: #ff5d53;
  // not stable, ask designer for correct value
  --palette-red-300: #ff8b8b;
  --palette-red-600: #ff4b40;
  // not stable, ask designer for correct value
  --palette-red-500: #ff5a5a;
  // not stable, ask designer for correct value
  --palette-red-700: #ff3737;
  // not stable, ask designer for correct value
  --palette-red-700-50: rgba(255, 55, 55, 0.5);
  // not stable, ask designer for correct value
  --palette-red-800: #ff2929;
  // not stable, ask designer for correct value
  --palette-red-900: #ff0000;

  --palette-yellow-400: #ffbd37;
  --palette-light-orange: #f5a96a;

  --palette-raswberry: #ff627f;
  --palette-pink: #f88dd3;
  --palette-cian: #01f1e3;
  --palette-violet: #9985ff;
  --palette-orange: #ff926a;

  --palette-font-weight-regular: 400;
  --palette-font-weight-medium: 500;
  --palette-font-weight-semibold: 600;
  --palette-font-weight-bold: 700;

  --palette-border-radius-xxs: 4px;
  --palette-border-radius-xs: 6px;
  --palette-border-radius-s: 8px;
  --palette-border-radius-sm: 10px;
  --palette-border-radius-m: 12px;
  --palette-border-radius-ml: 14px;
  --palette-border-radius-l: 18px;
  --palette-border-radius-xm: 16px;
  --palette-border-radius-xl: 20px;
  --palette-border-radius-xxl: 24px;
  --palette-border-radius-xxxl: 32px;

  --copy-trade-shadow: #a2a4ab1f;
}

:root {
  --color-trend-negative: var(--palette-red-400);
  --color-trend-positive: var(--palette-green-400);
  --color-body-bg: var(--palette-black);
  // text colors
  --color-heading: var(--palette-white);
  --color-subtitle: var(--palette-gray-200);
  --color-text-secondary: var(--palette-gray-200);
  --color-text-primary: var(--palette-white);
  --color-accent-heading-fragment: var(--palette-primary-700);

  // fw
  --font-weight-btn: var(--palette-font-weight-medium);
  --font-weight-heading: var(--palette-font-weight-bold);
  --font-weight-table-price-cell: var(--palette-font-weight-bold);
  --font-weight-nav-item: var(--palette-font-weight-medium);

  // primary btn
  --color-btn-primary-shadow: var(--palette-primary-700-50);
  --color-btn-primary-border: var(--palette-gray-900);
  --color-btn-primary-bg: var(--palette-primary-700);
  --color-btn-primary-text: var(--palette-white);
  --color-btn-primary-hover-bg: var(--palette-primary-800);
  --color-btn-primary-active-bg: var(--palette-primary-900);
  --color-btn-primary-disabled-bg: var(--palette-primary-500);
  --color-btn-primary-disabled-text: var(--palette-primary-300);
  --color-btn-primary-disabled-shadow: #486eff;
  --color-btn-primary-disabled-border: var(--palette-primary-300);

  // filled btn
  --color-btn-filled-border: var(--palette-primary-700);
  --color-btn-filled-bg: var(--palette-white);
  --color-btn-filled-text: var(--palette-primary-700);
  --color-btn-filled-hover-bg: var(--palette-primary-700);
  --color-btn-filled-hover-text: var(--palette-white);
  --color-btn-filled-active-bg: var(--palette-white);
  --color-btn-filled-active-border: var(--palette-primary-300);
  --color-btn-filled-active-text: var(--palette-primary-500);

  // outline btn
  --color-btn-outline-text: var(--palette-white);
  --color-btn-outline-border: var(--palette-white);
  --color-btn-outline-bg: transparent;

  --color-btn-outline-hover-border: var(--palette-primary-700);
  --color-btn-outline-hover-bg: var(--palette-primary-700);
  --color-btn-outline-hover-text: var(--palette-white);

  --color-btn-outline-active-text: var(--palette-primary-300);
  --color-btn-outline-active-border: var(--palette-primary-300);
  --color-btn-outline-active-bg: transparent;

  // social btn
  --color-btn-social-filled-bg: var(--palette-gray-900);
  --color-btn-social-filled-hover-bg: var(--palette-gray-600);

  --color-btn-social-outline-bg: transparent;
  --color-btn-social-outline-border: var(--palette-gray-500);

  --color-btn-social-outline-hover-bg: var(--palette-gray-500);
  --color-btn-social-outline-hover-border: var(--palette-gray-500);

  --color-btn-social-outline-active-bg: var(--palette-gray-400);
  --color-btn-social-outline-active-border: var(--palette-gray-500);

  --border-radius-btn-social: var(--palette-border-radius-xs);

  // text btn
  --color-btn-link-text: var(--palette-white);
  --color-btn-link-hover-text: var(--palette-primary-500);
  --color-btn-link-active-text: var(--palette-primary-300);

  --border-radius-cta-block-container: var(--palette-border-radius-l);
  --border-radius-sections-group: var(--palette-border-radius-m);
  @include tablet {
    --border-radius-sections-group: var(--palette-border-radius-l);
  }
  --border-radius-card: var(--palette-border-radius-s);
  --padding-card: var(--palette-border-radius-s);
  --color-card-bg: var(--palette-white);

  // tabs
  --color-tab-text: var(--palette-gray-300);
  --color-tab-border: var(--palette-gray-100);
  --color-tab-active-text: var(--palette-primary-700);
  --color-tab-hover-text: var(--palette-primary-700);
  --color-tab-active-border: var(--palette-primary-700);

  // table
  --color-table-heading-text: var(--palette-gray-200);
  --color-table-cell-text: var(--palette-white);
  --color-table-hover-cell-text: var(--palette-white);
  --color-table-row-bg: var(--palette-gray-800);
  --color-table-stripe-row-bg: var(--palette-gray-900);
  --color-table-row-hover-bg: var(--palette-primary-700);
  --border-radius-table: var(--palette-border-radius-m);
  --font-weight-table-cell: var(--palette-font-weight-medium);

  --padding-table-cell: 12px 8px;
  --padding-table-cell-first: 16px;
  --padding-table-cell-last: 16px;
  --height-table-row: 76px;
  --table-overflow-size: 18px;
  @include tablet {
    --padding-table-cell-first: 16px 8px;
    --padding-table-cell-last: 8px 16px;
  }
  @include desktop {
    --padding-table-cell-responsive: clamp(12px, 1.2vw, 24px);
    --padding-table-cell: var(--padding-table-cell-responsive) 8px;
    --padding-table-cell-first: var(--padding-table-cell-responsive) 8px;
    --padding-table-cell-last: 8px var(--padding-table-cell-responsive);
  }

  // chart
  --color-sparkline-chart-positive-stroke: var(--palette-green-400);
  --color-sparkline-chart-negative-stroke: var(--palette-red-400);
  --color-sparkline-chart-light-stroke: var(--palette-white);
  --color-sparkline-chart-positive-fill: #47ab72;
  --color-sparkline-chart-negative-fill: #ef4f45;
  --color-sparkline-chart-light-fill: var(--palette-white);

  --color-header-bg: rgba(10, 10, 10, 0.9);
  --color-header-text: var(--palette-white);
  --color-header-gift-banner-border: var(--palette-gray-200);
  --color-header-gift-banner-bg: rgba(255, 255, 255, 0.12);

  --container-padding: 16px;
  @include tablet {
    --container-padding: 40px;
  }
  --container-max-width: calc(1140px + 2 * var(--container-padding));
  --height-header: 56px;
  --height-second-nav-row: 36px;
  @include desktop {
    --height-header: 84px;
    --height-second-nav-row: 48px;
  }
  --height-app-banner: 0px;
  --color-app-banner-bg: var(--palette-white);
  --color-app-banner-close: var(--palette-gray-500);
  --color-rating-filled: #4a6fff;
  --color-rating-empty: #bccbff;

  // shadows
  --color-shadow-base: rgba(162, 164, 171, 0.12);
  --color-shadow-l: rgba(100, 100, 100, 0.25);
  --shadow-base: 2px 4px 60px 0 var(--color-shadow-base); // New Shadow
  --shadow-l: 14px 8px 16px 0 var(--color-shadow-l); // Icon Shadow
  --shadow-cards-light: 0px 4px 30px 0px rgba(146, 150, 166, 0.2); // Cards, Light Shadow
  --shadow-cards-dark: 0px 0px 30px 0px rgba(11, 11, 11, 0.4); // Cards/Dark shadow
  --shadow-cards-m: 0px 0px 30px 0px rgba(11, 11, 11, 0.2); // Cards/Medium dark
  --shadow-converters-table: 0 4px 30px 0 rgba(146, 150, 166, 0.2);
  --shadow-converters-bonus: 0 10px 15px 0 rgba(145, 149, 165, 0.2);

  // z-index
  --zIndices-hide: -1;
  --zIndices-auto: auto;
  --zIndices-base: 0;
  --zIndices-docked: 10;
  --zIndices-dropdown: 1000;
  --zIndices-sticky: 1100;
  --zIndices-banner: 1200;
  --zIndices-warnings: 1210;
  --zIndices-overlay: 1300;
  --zIndices-modal: 1400;
  --zIndices-popover: 1500;
  //--zIndices-skipLink: 1600;
  --zIndices-toast: 1700;
  --zIndices-tooltip: 1800;

  // spacings
  --vstack-spacing-sm: 24px;
  --vstack-spacing-md: 40px;
  --vstack-spacing-lg: 48px;
  --section-padding-sm: 40px;
  --section-padding-md: 48px;
  --section-padding-lg: 80px;
  --cards-grid-gap: clamp(16px, 1.6vw, 30px);

  // slider
  --slider-pagination-bullet-color: var(--palette-gray-300);
  --slider-pagination-bullet-active-color: var(--palette-white);
  --slider-pagination-bullet-opacity: 0.5;
  --slider-pagination-bullet-active-opacity: 1;
  --slider-chevron-color: white;
  --slider-chevron-hover-color: var(--palette-gray-300);
  --slider-chevron-active-color: var(--palette-gray-300);
  --border-radius-slider-arrow-button: var(--palette-border-radius-s);
  @include tablet {
    --border-radius-slider-arrow-button: var(--palette-border-radius-m);
  }

  --color-banner-bg: var(--palette-gray-900);

  // account type card
  --account-type-card-border-radius: var(--palette-border-radius-m);
  --color-account-type-card-border: var(--palette-gray-100);
  --color-account-type-card-bg: var(--palette-white);
  --color-account-type-card-shadow: rgba(146, 150, 166, 0.2);
  --color-account-type-card-heading-bg: var(--palette-gray-70);
  --color-account-type-heading: var(--palette-primary-700);
  --font-weight-account-type-card-feature-name: var(--palette-font-weight-semibold);
  --color-account-type-card-dark-bg: var(--palette-gray-900);
  --color-account-type-card-dark-heading-bg: var(--palette-gray-700);
  --color-account-type-card-dark-heading: var(--palette-white);

  // fees card
  --color-fees-card-bg: var(--palette-white);
  --border-radius-fees-card: var(--palette-border-radius-m);
  --color-fees-card-main-condition-border: var(--palette-primary-100);
  --color-fees-card-main-condition-bg: var(--palette-primary-50);
  --color-fees-card-main-condition-value: var(--palette-primary-700);
  --color-fees-card-condition-value: var(--palette-gray-500);
  --font-weight-fees-card-condition-name: var(--palette-font-weight-semibold);
  --font-weight-fees-card-main-condition-value: var(--palette-font-weight-bold);
  @include tablet {
    --font-weight-fees-card-main-condition-value: var(--palette-font-weight-semibold);
  }

  --color-one-row-benefits-separator: var(--palette-gray-600);
  --border-radius-slider-controls-button: var(--palette-border-radius-s);
  --color-slider-controls-button-bg: var(--palette-primary-700);
  --color-slider-controls-button-hover-bg: var(var(--palette-primary-800));
  --color-slider-controls-button-shadow: rgba(11, 11, 11, 0.2);

  --border-radius-small-assets-table: var(--palette-border-radius-m);
  --color-small-assets-table-bg: var(--palette-white);
  --font-weight-small-assets-table-asset-cell: var(--palette-font-weight-semibold);
  --color-small-assets-table-regular-cell-text: var(--palette-gray-500);

  --border-radius-nav-product-label: var(--palette-border-radius-xm);

  // benefits static
  --border-radius-benefit-static-card: var(--palette-border-radius-m);

  // mt step card
  --color-step-square-bg: var(--palette-primary-700);
  --color-step-square: var(--palette-white);
  --border-radius-conditions: var(--palette-border-radius-m);
  --border-radius-step-square: var(--palette-border-radius-s);
  --border-radius-step-card: var(--palette-border-radius-m);

  // service card
  --border-radius-service-card: var(--palette-border-radius-xl);
  @include tablet {
    --border-radius-service-card: var(--palette-border-radius-xxl);
  }

  --transition-fn-ease-out-cubuc: cubic-bezier(0.25, 1, 0.5, 1);
  --transition-fn-smooth-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --store-button-transition-fn: var(--transition-fn-ease-out-cubuc);
  // product card
  --border-radius-product-card: var(--palette-border-radius-xl);
  @include tablet {
    --border-radius-product-card: var(--palette-border-radius-xxl);
  }
  --product-slide-transition: all 300ms ease-in-out;
  --product-slide-hover-transition: all 300ms var(--transition-fn-ease-out-cubuc);

  // image
  --image-wrapper-border-radius-s: var(--palette-border-radius-s);
  --image-wrapper-border-radius-m: var(--palette-border-radius-m);
  --image-wrapper-shadow-s: 1px 2px 36px 0 var(--color-shadow-base);
  --image-wrapper-shadow-m: 2px 4px 60px 0 var(--color-shadow-base);

  // icons
  --icon-shadow: var(--shadow-l);
  --icon-box-border-radius: 14px;
  --icon-box-color: var(--palette-white);

  // top icon card
  --top-icon-card-shadow: var(--shadow-base);
  --top-icon-card-border-radius: var(--palette-border-radius-m);

  // warnings
  --warnings-banner-border-radius: var(--palette-border-radius-m);
  //--risk-warning-title-color: var(--palette-red-400);
  --risk-warning-title-color: var(--palette-primary-700);

  --overlay-bg-color: rgba(10, 10, 10, 0.6);

  // menu
  --select-menu-border-radius: var(--palette-border-radius-s);

  // converter-widget
  --converter-widget-border-radius: var(--palette-border-radius-m);

  // nav items
  --nav-item-arrow-transform: rotate(180deg);
  --nav-item-arrow-transition: transform 110ms ease-in-out;
}
